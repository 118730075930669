import React, { useEffect, useState} from "react";
import DataGrid, { Scrolling, Pager, Paging, Column, SearchPanel} from 'devextreme-react/data-grid';
import _translate from "../Globals/translation";
import { API_URL } from "../Globals/globalVariables";

interface StatisticsParameters {
    sessionTokenAuth: String;
    username: String;
    userid: String;
}
interface InformationList {
    filename: string;
    id: string;
    time: string;
}

const debugging = false;

const InvoiceOverview:React.FC<StatisticsParameters> = ({sessionTokenAuth, userid, username}) => {
    useEffect(() => {
        // set Title of the Page for SEO reasons
        document.title = "Berlin SMS - "  + _translate("Invoices");
      }, []);

    const [data, setData] = useState<Array<InformationList>>([]);
    // getting the user data
    const getInvoices = async () => {
        let response = await fetch(API_URL+'/invoices', {method:'GET', 
            headers: {'Authorization': 'Bearer ' + sessionTokenAuth.toString()}});
        if(!response.ok){
            alert(_translate("Sorry something went wrong. The site was unable to request the current Invoices."));
        } else {
            let answer = await response.json();
            if(debugging) console.log(answer);
            // getting rid of the empty dicts
            var invoiceList = answer.invoices.filter((e: any) => {
                if(e === undefined || e === null) return false;
                if(Object.keys(e).length !== 0) return true; else return false;
            });
            // converting the timestamp to a readable date
            invoiceList.forEach((item: any) => {
                // e.ctimeMs = convertMStoDateString(e.ctimeMs);
                if(item.time === undefined || Number(item.time) < 1577836800000) item.created = ''; // 1577836800000 = 01.01.2020
                else item.time = new Date(Number(item.time)).toLocaleDateString('de-DE', {year: '2-digit', month: '2-digit', day: '2-digit'});

            });
            setData(invoiceList);
        }
    }    
    const getSpecificInvoice = async (filename: string) => {
        let response = await fetch(API_URL+'/invoice?filename=' + filename, {method:'GET', 
            headers: {'Authorization': 'Bearer ' + sessionTokenAuth.toString()}});
        if(!response.ok){
            alert(_translate("Sorry something went wrong. The site was unable to request the current Invoice."));
        } else {
            let answer = await response.json();
            if(debugging) console.log(answer.filename);
            if(debugging) console.log(answer.pdf);
            
            var invoiceArr = base64ToArrayBuffer(answer.pdf);
            saveByteArray(answer.filename, invoiceArr);
        }
    }

    // call once to get initial values
    useEffect(() => {
        getInvoices();
    }, []);

    const onRowClick = (e: any) => {
        getSpecificInvoice(e.data.filename);        
    };

    function base64ToArrayBuffer(base64: string) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
           var ascii = binaryString.charCodeAt(i);
           bytes[i] = ascii;
        }
        return bytes;
     }
     function saveByteArray(reportName: string, byte: any) {
        var blob = new Blob([byte], {type: "application/pdf"});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    };

    return <div className="center">
        <div className="content-container invoices">
            <h1 className="header1 header-invoices">{_translate("Invoices")}</h1>
            <DataGrid
                id='gridContainer'
                dataSource={data}
                showBorders={true}
                columnAutoWidth={true}
                onRowClick={onRowClick}
                columnHidingEnabled={true}
            >
                {/* <Column cssClass="devextreme_custom_column invoice-column-1" caption={_translate("ID")} dataField="id"></Column> */}
                <Column cssClass="devextreme_custom_column invoice-column-2" caption={_translate("Filename")} dataField="filename"></Column>
                <Column cssClass="devextreme_custom_column invoice-column-3" caption={_translate("Date created")} dataField="time"></Column>
                <Scrolling rowRenderingMode='virtual'></Scrolling>
                <Paging defaultPageSize={10} />
                <Pager
                    visible={true}
                    allowedPageSizes={true}
                    displayMode={'full'}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true} />
                <SearchPanel visible={true} width={240} placeholder={_translate("Search...") } />
            </DataGrid> 
        </div>
    </div>
}

export default InvoiceOverview;
