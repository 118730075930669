import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import logo from "../../images/BerlinSMS_Symbol.svg";
import _translate from "../Globals/translation";
import cookiehandler from "../../cookies";
import { API_URL } from "./globalVariables";
import { ArgumentFormat } from "devextreme-react/chart";


const Sidebar = (args: {logout: () => void, isLoggedIn: Boolean, accountType: "sub" | "main", maxAccounts: number | null, WEBSITEVERSIONNUMBER: string}) => {
    const [apiversion, setApiversion] = useState<String | null>(null);

    const getApiVersion = async () => {
        let response = await fetch(API_URL+'/version', {method:'GET'});
        if(!response.ok){
            alert(_translate("Sorry something went wrong. The site was unable to request the current API Version."));
        } else {
            let answer = await response.json();
            setApiversion(answer.version);
        }
    }

    const getDefaultLanguage = () => {
        let language = cookiehandler.getCookie("language");
        if(language === "") {
            language = "Ger";
            cookiehandler.setCookie("language", language, "/", window.location.hostname);
        }
        return language;
    }
    
    // call once - when created
    useEffect(() => {
        getApiVersion();
    }, []);

    return <div> 
        { args.isLoggedIn ? <div className='sidebar'> 
            <img src={logo} alt="BerlinSMS logo" onClick={() => window.location.assign("https://app.berlinsms.de")}></img>
            <input type="checkbox" name="hamburger-check" className="hamburger-check"/>
            <div className="hamburger-lines">
            <span className="line line1 top"></span>
            <span className="line line2 mid"></span>
            <span className="line line3 bottom"></span>
            </div>
            <div className="sidebar_items">
                <div className="language-container">
                    <select className="select language-select" defaultValue={getDefaultLanguage()} onChange={(event) => {cookiehandler.setCookie("language", event.target.value, "/", window.location.hostname); window.location.reload();}}>
                        <option value="Ger">&#127465;&#127466;</option>
                        <option value="Eng">&#127468;&#127463;</option>
                    </select>
                </div>
                <div className="links-container">
                    <Link className="componentlink link-dashboard" to="/dashboard">{_translate("Dashboard")}</Link>
                    <Link className="componentlink link-profile" to="/profile">{_translate("My Profile")}</Link>
                    <Link className="componentlink link-sendsms" to="/sendsms">{_translate("Send SMS")}</Link>
                    <Link className="componentlink link-reports" to="/Reports">{_translate("Reports")}</Link>
                    {/* <Link className="componentlink link-messaging" to="/messaging">{_translate("Messaging")}</Link> */}
                    {/* Account sub has no Invoices, Balance nor 2FA */}
                    {args.accountType === "main" ? <>
                        <Link className="componentlink link-invoices" to="/invoices">{_translate("Invoices")}</Link>
                        <Link className="componentlink link-balance" to="/balance">{_translate("Balance")}</Link>
                        {args.maxAccounts !== null && args.maxAccounts > 0 ? <Link className="componentlink link-account-manager" to="/account-manager">{_translate("Account Manager")}</Link> : <></>}
                        {/* <a className="componentlink link-twofa" href="https://twofa.berlinsms.de" onClick={() => window.location.assign("https://twofa.berlinsms.de")}>
                            {_translate("2FA")}
                        </a> </> */}
                        <Link className="componentlink link-twofa" to="/keygen">{_translate("2FA")}</Link> 
                    </> : <></>}
                    <button className="button button-logout" onClick={ args.logout }>{_translate("Logout")}</button> 
                </div>
                <div className="versions-container">
                    <span className="bottom version-webiste">{_translate("Webversion")}: {args.WEBSITEVERSIONNUMBER}</span>
                    <span className="bottom version-api">{_translate("API Version: ")}{apiversion == null ? _translate("not available") : apiversion} </span>
                </div>
            </div> 
          </div> : null
        }
    </div>
}

export default Sidebar