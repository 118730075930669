import React, {useEffect, useRef, useState} from "react";
import {Buffer} from 'buffer';
import cookiehandler from "../../cookies";
import _translate, {_getEventText} from "../Globals/translation";
import { Link, useSearchParams } from "react-router-dom";
import { API_URL, REACT_APP_SITE_KEY_BERLINSMS } from "../Globals/globalVariables";
import { toggleButtonLoading } from "../Globals/globalFunctions";
import { TwoFA } from "../2FALoginValidator";

interface loginParameters {
    validateLogin: () => void;
    event: string | undefined;
}

const Login: React.FC<loginParameters> = ({validateLogin, event}) => {
    useEffect(() => {
        // set Title of the Page for SEO reasons
        document.title = "Berlin SMS - "  + _translate("Login");
      }, []);
    
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const loginButtonRef = useRef<HTMLButtonElement>(null);

    // get the event from the url   
    const [searchParams, ] = useSearchParams();
    const [paramEvent, setParamEvent] = useState<string|undefined>(event);
    useEffect(() => {
        let params = searchParams.get("event");
        let namen = searchParams.get("namen");
        let Passwort = searchParams.get("Passwort");
        if(namen !== null && Passwort !== null) {
            // console.log("Login with params");
            // console.log(namen);
            // console.log(Passwort);
            login_sender(namen, Passwort);
        }
        if(params !== null) setParamEvent(params);
    }, [searchParams]);

    const login_sender = async (_username: string, _password: string) => {
        // disable Login Button
        toggleButtonLoading(loginButtonRef, "loading");
        // request current login based on login data
        // console.log("Login with: "+_username+" "+_password);
        let response = await fetch(API_URL+'/login', {method:'GET', 
            headers: {'Authorization': 'Basic ' + Buffer.from(_username + ':' + _password).toString('base64')}});
        if(!response.ok){
            //console.log("Login failed");
            alert(_translate("Login failed - check your credentials."));
        } else {
            let data = await response.json();
            // delete old cookies - domain is current host, because for coding I use localost and not app.berlinsms.de it has to be requested
            // cookiehandler.delete_cookie("uid", "/", window.location.hostname);
            cookiehandler.delete_cookie("sessionTokenAuth", "/", window.location.hostname);
            // cookiehandler.delete_cookie("accountType", "/", window.location.hostname);
            // Set it new cookies
            cookiehandler.setCookie("sessionTokenAuth",data.token, "/", window.location.hostname);
            // cookiehandler.setCookie("uid",data.userId, "/", window.location.hostname);
            // cookiehandler.setCookie("accountType",data.accountType, "/", window.location.hostname);
            validateLogin();
        }
        // enable Login Button
        toggleButtonLoading(loginButtonRef, "default", _translate("Login"));
    } 
    const onVerify_TWOFA = (token: string, code?: string) => {
        cookiehandler.delete_cookie("sessionTokenAuth", "/", window.location.hostname);
        cookiehandler.setCookie("sessionTokenAuth",token, "/", window.location.hostname);
        validateLogin();
    }

    return <div className="fill-space">
        <div className="center">
            <div className="content-container login">
                <h1 className="header1 header-login">{_translate("Login")}</h1>
                {/* {paramEvent !== undefined ? <span className="textspan param-event-textspan">{_translate(_getEventText(paramEvent, "login", "textspan"))}</span> : null} */}
                { /* if there is a special event this block will be added */ event !== undefined ? 
                    <div className="special-event-container">
                        <h2 className="header2 header-login special-event-header">{_translate(_getEventText(event, "login", "header"))}</h2>
                        <span className="textspan special-event-textspan">{_translate(_getEventText(event, "login", "textspan"))}</span>
                    </div> : null
                }
                <div className="login-text-container">
                    <h2 className="header2 header-login">{_translate("Register/Login with Phonenumber.")}</h2>
                    <span className="textspan login-text">{_translate("If you already have an account with a phonenumber you can easily login with a one time password. If you dont have an account yet an account will be created using your phonenumber.")}</span>
                </div>
                <hr></hr>
                <div className="login-inputs-container">
                    <TwoFA
                        bsmsSitekey = {REACT_APP_SITE_KEY_BERLINSMS}
                        onVerify={onVerify_TWOFA}
                    />
                </div>
                <hr></hr>
                <div className="login-text-container">
                    <h2 className="header2 header-login">{_translate("Login with Username and Password.")}</h2>
                    <span className="textspan login-text">{_translate("If you have an account without a phonenumber you can still login using the old method.")}</span>
                </div>
                <div className="login-inputs-container">
                    <div className="login-username-container">
                        <label className="textlabel username-description">{_translate("Username")}</label>
                        <input className="inputfield input-username" onInput={e => setUsername((e.target as HTMLInputElement).value)} required placeholder="max@mustermail.de"/>
                    </div>
                    <div className="login-password-container">
                        <label className="textlabel password-description">{_translate("Password")}</label>
                        <input className="inputfield input-password" type="password" onInput={e => setPassword((e.target as HTMLInputElement).value)} required placeholder="*********"/>
                    </div>
                </div>
                <div className="login-button-container">
                    <button ref={ loginButtonRef } className="button submitbutton button-login" onClick={() => login_sender(username, password) }>{_translate("Login")}</button>
                    {/* <Link className="componentlink link-register" to={"/register" + event !== undefined ? "-" + event : ""}>{_translate("Don't have a login? Register here!")}</Link> */}
                    {/* <Link className="componentlink link-register" to={event!==undefined && event!==null && event !== "" ? "/register-" + event : "/register"}>{_translate("Don't have a login? Register here!")}</Link> */}
                </div>
            </div>
        </div>
    </div>
}

export default Login
