import React, {useEffect, useState} from "react";
import TwoFAHeader from "./twoFA-Header";
import TwoFAEditSite from "./twoFA-EditSite";
import {twofaSite} from "./twoFA-Variables";
import _translate from "../Globals/translation";
import { API_URL } from "../Globals/globalVariables";

interface twoFAHeaderParameters {
    sessionTokenAuth: String;
    userID: String;
}

const TwoFASite:React.FC<twoFAHeaderParameters> = ({sessionTokenAuth, userID}) => {
    useEffect(() => {
        // set Title of the Page for SEO reasons
        document.title = "Berlin SMS - "  + _translate("2FA Settings");
      }, []);

    const [twofaSites, setTwofaSites] = useState<Array<twofaSite>>([]); // Array of all TwoFA Sites 
    const [prevSelectedSite, setPrevSelectedSite] = useState<twofaSite | undefined>(undefined); // The previously selected site - used to set the selected site after the sites are reloaded
    const [selectedSite, setSelectedSite] = useState<twofaSite | undefined>(undefined); // The selected site
    
    // getting user informations    
    const getAccounts = async () => {
        // request the current twoFA sites 
        let response = await fetch(API_URL + '/twofa/accounts', {method:'GET', 
            headers: {'Authorization': 'Bearer ' + sessionTokenAuth.toString()}});
        if(!response.ok){
            // Failed to get the current twoFA sites
            alert(_translate("Sorry something went wrong. The site was unable to request the current TwoFA Sites. Please try again later."));
        } else {
            let data = await response.json(); // get the data
            let _twofaSites: Array<twofaSite> = []; // create a new array
            data.forEach((entry: any) => { // iterate over the data and add the data to the array
                _twofaSites.push({
                    id: entry.id,
                    twofaType: entry.twofaType,
                    twofaLabel: entry.twofaLabel,
                    twofaSitekey: entry.twofaSitekey,
                    twofaSecretkey: entry.twofaSecretkey,
                    domains: entry.domains,
                    captchaType: entry.captchaType,
                    captchaSitekey: entry.captchaSitekey,
                    captchaSecretkey: entry.captchaSecretkey
                });
            });
            setTwofaSites(_twofaSites); // set the new array
        }
    } 

    const onClickSettings = () => { // TODO: Add settings
        alert("TODO: Add settings");
    }
    const onClickStatistics = () => { // TODO: Add statistics
        alert("TODO: Add statistics");
    }
    const onClickDeleteSite = async () => { // Function to delete a site
        if(selectedSite === undefined || selectedSite.id === undefined || selectedSite.id === ""){ // check if a site is selected (new site and no site will fail here)
            alert(_translate("Sorry something went wrong. The site was unable to delete the TwoFA Site. Please try again later.")); // if not selected or existing show an error 
        } else { // if selected and existing
            let response = await fetch(API_URL + '/twofa/account/' + selectedSite.id, {method:'DELETE',
                headers: {'Authorization': 'Bearer ' + sessionTokenAuth.toString(), 'Content-Type': 'application/json'}}); // delete the site
            if(!response.ok){ 
                alert(_translate("Sorry something went wrong. The site was unable to delete the TwoFA Site. Please try again later.")); 
            } 
        }
        getAccounts(); // get the new sites (without the deleted one)
        setPrevSelectedSite(undefined); // set the previous selected site to undefined
    }

    const saveSite = async (site: twofaSite) => { // Function to save or add a site
        var returnVal: boolean = false; // return value
        if(site.id === undefined || site.id === ""){ // check if the site is new or existing
            // create new site
            let response = await fetch(API_URL + '/twofa/account', {method:'POST',
                headers: {'Authorization': 'Bearer ' + sessionTokenAuth.toString(), 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "twofaLabel": site.twofaLabel,
                    "twofaType": site.twofaType,
                    "captchaType": site.captchaType,
                    "captchaSitekey": site.captchaSitekey,
                    "captchaSecretkey": site.captchaSecretkey,
                    "domains": site.domains
                })
            });
            if(!response.ok){
                alert(_translate("Sorry something went wrong. The site was unable to save the new TwoFA Site. Please try again later."));  
            } else {
                const data = await response.json();
                site = data;
                console.log(site);
                returnVal = true;
            } 
        } else {
            // update existing site
            let response = await fetch(API_URL + '/twofa/account/' + site.id, {method:'PUT',
                headers: {'Authorization': 'Bearer ' + sessionTokenAuth.toString(), 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "twofaLabel": site.twofaLabel,
                    "twofaType": site.twofaType,
                    "captchaType": site.captchaType,
                    "captchaSitekey": site.captchaSitekey,
                    "captchaSecretkey": site.captchaSecretkey,
                    "domains": site.domains
                })
            });
            if(!response.ok){
                alert(_translate("Sorry something went wrong. The site was unable to update the TwoFA Site. Please try again later."));
            } else {
                returnVal = true;
            }
        }        
        getAccounts(); // get the new sites (with the new or updated one)
        setPrevSelectedSite(site); // set the previous selected site to the new or updated one
        return returnVal;
    }

    
    useEffect(() => {
        getAccounts(); // Get the current TwoFA sites when the component is loaded
    }, []); 

    return <div className="twoFA-content-container">
            <TwoFAHeader    sessionTokenAuth={sessionTokenAuth} 
                            userID={userID}
                            prevSelectedSite={prevSelectedSite}
                            twofaSites={twofaSites}
                            setSelectedSite={(site) => setSelectedSite(site)}
                            onClickSettings={onClickSettings}
                            onClickStatistics={onClickStatistics}
                            onClickDeleteSite={onClickDeleteSite}/>
            {selectedSite === undefined ?
                <div className="twoFA-content twoFA-no-site-selected">
                    <h2 className="header2 tutorial-header">{_translate("Create a Site")}</h2>
                    <span className="textspan textspan-tutorial-steps textspan-tutorial-step-1">{_translate("After they press the button, a form appears. In this form you need to enter your data, such as domains or captcha keys. After you save the page, the BerlinSMS TwoFA Keys will be automatically generated and displayed in the form.")}</span>
                    <h3 className="header3 tutorial-step tutorial-step-2">{_translate("Websitelabel")}</h3>
                    <span className="textspan textspan-tutorial-steps textspan-tutorial-step-2">{_translate("The website label is the name of your configuration. This name is necessary to distinguish between different configurations.")}</span>
                    <h3 className="header3 tutorial-step tutorial-step-3">{_translate("TwoFA Type")}</h3>
                    <span className="textspan textspan-tutorial-steps textspan-tutorial-step-3">{_translate("Use the TwoFA type to select the verification method you want to add to your website. Currently supported are Optin(SMS) and Optin(Mail).")}</span>
                    <h3 className="header3 tutorial-step tutorial-step-4">{_translate("Captcha Keys")}</h3>
                    <span className="textspan textspan-tutorial-steps textspan-tutorial-step-4">{_translate("You have to register the captcha keys independently via the respective provider. When registering a captcha, you will receive two keys. Once a sitekey and a secretkey. In order for our TwoFA component to protect you from automated requests, it is necessary to provide us with these two keys. Currently the captcha types ReCaptcha and HCaptcha are supported.")}</span>
                    <h3 className="header3 tutorial-step tutorial-step-5">{_translate("TwoFA Keys")}</h3>
                    <span className="textspan textspan-tutorial-steps textspan-tutorial-step-5">{_translate("After you have filled in all fields (except this one), you can save your configuration. Afterwards the TwoFA Keys will be generated and displayed at this point. You can then use these to integrate our component on your website.")}</span>
                    <h3 className="header3 tutorial-step tutorial-step-6">{_translate("Domains")}</h3>
                    <span className="textspan textspan-tutorial-steps textspan-tutorial-step-6">{_translate("Domains are the actual web addresses under which your component will run. For each request, the system checks whether the requesting domain corresponds to one of the stored domains. A distinction must also be made here between subdomains (e.g. app.berlinsms.de, twofa.berlinsms.de, ...). For local testing, domains such as localhost also work.")}</span>
                </div> 
            : <TwoFAEditSite  sessionTokenAuth={sessionTokenAuth}
                            userID={userID}
                            twofaSite={selectedSite}
                            saveSite={(site) => saveSite(site)}/>}
        </div>
}

export default TwoFASite