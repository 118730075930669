import React, {useEffect, useState} from "react";
import _translate from "../Globals/translation";
import { API_URL } from "../Globals/globalVariables";
import { fixedEncodeURIComponent, toggleButtonLoading } from "../Globals/globalFunctions";
import cookiehandler from "../../cookies";

interface SendingParameters {
    apikey: String;
}


const SendSMS: React.FC<SendingParameters> = ({apikey}) => {
    useEffect(() => {
        // set Title of the Page for SEO reasons
        document.title = "Berlin SMS - "  + _translate("Send SMS");
      }, []);

    const [messageText, setmessageText] = useState<string>("");
    const [phonenumber, setPhonenumber] = useState<number | null>(null);
    const [displayPhonenumber, setDisplayPhonenumber] = useState<string | null>(null);
    const [messageSend, setmessageSend] = useState<Boolean>(false);
    const [refNumber, setRefNumber] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [enableButton, setEnableButton] = useState<Boolean>(false);
    const [lastNumbers, setLastNumbers] = useState<Array<string>>([]);
    const sendButtonRef = React.createRef<HTMLButtonElement>();

    useEffect(() => {
        cookiehandler.getCookie("lastNumbers") === null ? setLastNumbers([]) : setLastNumbers(cookiehandler.getCookie("lastNumbers").split(","));
    }, []);

    const messageSender = async () => {
        setmessageSend(false);
        
        if(phonenumber === null){
            alert(_translate("Please insert a valid phonenumber!"));
            return;
        } else if (messageText === ""){
            alert(_translate("Please insert a valid message text!"));
            return;
        }

        var url = API_URL + '/send/sms/' + phonenumber + '/' + fixedEncodeURIComponent(messageText);
        toggleButtonLoading(sendButtonRef, "loading");
        let response = await fetch(url, {method:'POST', 
            headers: {'apikey': apikey.toString()}});
        if(!response.ok){
            alert(_translate("Sending failed! Please check your Balance or inform the support."));
            toggleButtonLoading(sendButtonRef, "default", _translate("Send"));
        } else {
            setmessageSend(true);
            let answer = await response.json();
            setRefNumber(answer.ref);
        }
        // Add the phonenumber to the lastNumbers
        let _lastNumbers = cookiehandler.getCookie("lastNumbers") === null ? [] : cookiehandler.getCookie("lastNumbers").split(",");
        // avioid duplicates
        if (_lastNumbers.indexOf(phonenumber.toString()) === -1){
            // remove empty strings
            _lastNumbers = _lastNumbers.filter((nr) => nr !== "");
            // add the new number
            _lastNumbers.push(phonenumber.toString());
            // limit the lastNumbers to 10
            if(_lastNumbers.length > 10){
                _lastNumbers.shift();
            }
            cookiehandler.setCookie("lastNumbers", _lastNumbers.join(","), "/", "");
            setLastNumbers(_lastNumbers);
        }
    }
    useEffect(() => {
        if (messageSend){
            toggleButtonLoading(sendButtonRef, "default", _translate("Send"));
        }
    } , [messageSend])

    const onInput_and_numbervalidator = (inputnumber: string) => {
        var _phonenumber: string = inputnumber.replaceAll(" ", "").replace("+", "");
        const minlength = 8;
        var _errormessage_ = "";
        const maxlength = 19

        // check if there are only numbers in the input
        if(!/^\d+$/.test(_phonenumber) && _phonenumber !== ""){
            setPhonenumber(null);
            setDisplayPhonenumber(null);
            setErrorMessage(_translate("Please insert only numbers!").toString());
        }
        if(_phonenumber !== null && _phonenumber !== ""){
            var outputnr: string = "";
            // check if the number starts with 0049 or 49 or 0
            // if the number starts with 0049 or 49 remove the first 0
            // if the number starts with 0 remove the first 0 and add 49
            if(_phonenumber.slice(0, 4) === "0049"){
                _phonenumber = "49"+ _phonenumber.slice(4);
                outputnr = "+" + _phonenumber.slice(0, 2);
            } else if(_phonenumber.slice(0, 3) === "049"){
                _phonenumber = "49"+ _phonenumber.slice(3);
                outputnr = "+" + _phonenumber.slice(0, 2);
            } else if(_phonenumber.slice(0, 1) === "0"){
                // remove the first 0
                _phonenumber = "49" + _phonenumber.slice(1);
                outputnr = "+" + _phonenumber.slice(0, 2);
            } else if(_phonenumber.slice(0, 2) === "49"){
                outputnr = "+" + _phonenumber.slice(0, 2);
            } else {
                outputnr = "+" + _phonenumber.slice(0, 2);
            } 
            setPhonenumber(Number(_phonenumber));
            
            // set the output number
            outputnr += " ";
            outputnr += _phonenumber.slice(2);
            // outputnr += _phonenumber.slice(2, 5);
            // outputnr += " ";
            // outputnr += _phonenumber.slice(5);
            setDisplayPhonenumber(outputnr);
            // console.log("Phonenumber: " + _phonenumber + " DisplayPhonenumber: " + outputnr);
        } 

        // check if the number is too small or too big
        if(_phonenumber.length - 2 < minlength){
            _errormessage_ = _translate("Number is too small. Min. required numbers is: ") + minlength.toString();
            setPhonenumber(null);
            setDisplayPhonenumber(null);

        } else if (_phonenumber.length - 2 > maxlength){
            _errormessage_ = _translate("Number is too big. Max. numbers is: ") + maxlength.toString();
            setPhonenumber(null);
            setDisplayPhonenumber(null);
        }

        // check if the button should be enabled or not
        // console.log(_errormessage_ + " " + messageText);
        // console.log("err: ",     _errormessage_ === "");
        // console.log("msg: ", messageText !== "");
        if (_errormessage_ === ""){
            // console.log("Button should be enabled");
            setEnableButton(true);
        } else {
            setEnableButton(false);
            // console.log("Button should be disabled");
        }
        setErrorMessage(_errormessage_);
    } 

    return <div className="fill-space">
        <div className="center">
            <div className='content-container sendSMS'>
                <h1 className="header1 header-sendSMS">{_translate("Send a SMS to a Phonenumber")}</h1>
                <div className="phonenumber-input-container">
                    <label className="textlabel phonenumber-description">{_translate("Phonenumber")}</label>
                    <input className="inputfield input-phonenumber" list="lastNumbers" onInput={e => onInput_and_numbervalidator((e.target as HTMLInputElement).value)} required />
                    <datalist id="lastNumbers">
                        {lastNumbers.map((nr: string, index) => {
                            return nr === "" ? <></> : <option value={nr} key={index}/>})}
                    </datalist>
                    <label className="textlabel phonenumber-inserted">{_translate("Inserted phone number: ")}{displayPhonenumber === null ? _translate(errorMessage) : displayPhonenumber.toString()}</label>
                </div>
                <div className="message-display-container">
                    <label className="textlabel messagetext-description">{_translate("Message Text")}</label>
                    <textarea className="input input-messagetext" onInput={e => setmessageText((e.target as HTMLInputElement).value)} placeholder={_translate("You can insert your message text here...").toString()} required></textarea>
                </div>
                <div className="button-container">
                    <button ref={ sendButtonRef } className="button submitbutton button-sendsms" onClick={ messageSender } disabled={!enableButton}>{_translate("Send")}</button>
                </div>
                { messageSend ? <span className="textspan successmessage" id="infobox">{_translate("Message was send. The reference number is: ") + refNumber.toString()}</span> : <span className="textspan errormessage" id="infobox">{errorMessage}</span>}
            </div>
        </div>
    </div>
}

export default SendSMS